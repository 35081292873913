import React from 'react';

const MinimizeIcon: React.JSX.Element = (
  <>
    <svg
      width='20'
      height='20'
      viewBox='0 0 24 24'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4 14H10V20'
        stroke='white'
        strokeWidth='1.5' // Reduced stroke width for a skinnier appearance
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20 10H14V4'
        stroke='white'
        strokeWidth='1.5' // Reduced stroke width for a skinnier appearance
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14 10L21 3'
        stroke='white'
        strokeWidth='1.5' // Reduced stroke width for a skinnier appearance
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3 21L10 14'
        stroke='white'
        strokeWidth='1.5' // Reduced stroke width for a skinnier appearance
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  </>
);

export default MinimizeIcon;
