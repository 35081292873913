import React from 'react';
import { formatIsoToHHMM } from 'utils/time';
import './ReviewedByCard.css';
import { ReviewEvent } from 'types';

type ReviewedByCardProps = {
  timeLineObject: ReviewEvent;
};
export default function ReviewedByCard({ timeLineObject }: ReviewedByCardProps) {
  const formattedDate = formatIsoToHHMM(timeLineObject.event_datetime);
  return (
    <div className='reviewed-by-container'>
      <div className='reviewed-by-text'>Reviewed by {timeLineObject.user_name}</div>
      <div className='reviewed-by-date'>{formattedDate}</div>
    </div>
  );
}
