import { utilities } from '@cornerstonejs/core';
import { WindowLevelTool } from '@cornerstonejs/tools';

class CustomWindowLevelTool extends WindowLevelTool {
  static toolName = 'CustomWindowLevel';

  getNewRange({ viewport, deltaPointsCanvas, volumeId, lower, upper }) {
    // Add your custom multiplier here (smaller = less sensitive)
    const sensitivityMultiplier = 0.5;

    // eslint-disable-next-line no-underscore-dangle
    const dynamicRangeMultiplier = this._getMultiplierFromDynamicRange(viewport, volumeId);
    const defaultMultiplier = 4;
    const effectiveMultiplier = dynamicRangeMultiplier || defaultMultiplier;
    const multiplier = effectiveMultiplier * sensitivityMultiplier;

    const wwDelta = deltaPointsCanvas[0] * multiplier;
    const wcDelta = deltaPointsCanvas[1] * multiplier;
    const { windowLevel } = utilities;

    let { windowWidth, windowCenter } = windowLevel.toWindowLevel(lower, upper);

    windowWidth += wwDelta;
    windowCenter += wcDelta;

    windowWidth = Math.max(windowWidth, 1);
    windowCenter = Math.max(windowCenter, 1);

    return utilities.windowLevel.toLowHighRange(windowWidth, windowCenter);
  }
}

export default CustomWindowLevelTool;
