import useLoading from 'hooks/useLoading';
import React from 'react';
import './Spinner.css';

type SpinnerProps = {
  useProgress?: boolean;
  externalLoadingState?: boolean;
};

const Spinner: React.FC<SpinnerProps> = ({
  useProgress = true,
  externalLoadingState = false,
}) => {
  const { loadingProgress, isLoadingAnVolume } = useLoading();

  if (!isLoadingAnVolume && !externalLoadingState) {
    return null;
  }

  return (
    <div className='spinner-container'>
      <div className='spinner'></div>
      {useProgress && <div className='loading-progress'>{loadingProgress}%</div>}
    </div>
  );
};

export default Spinner;
