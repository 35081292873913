import React from 'react';

export default function CtaTimelineIcon() {
  return (
    <svg width='35' height='66' viewBox='0 0 35 66' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='35' height='35' rx='17.5' fill='#7486AE' />
      <path
        d='M11.875 14.375V13.125C11.875 12.7935 12.0067 12.4755 12.2411 12.2411C12.4755 12.0067 12.7935 11.875 13.125 11.875H14.375'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.625 11.875H21.875C22.2065 11.875 22.5245 12.0067 22.7589 12.2411C22.9933 12.4755 23.125 12.7935 23.125 13.125V14.375'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.125 20.625V21.875C23.125 22.2065 22.9933 22.5245 22.7589 22.7589C22.5245 22.9933 22.2065 23.125 21.875 23.125H20.625'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.375 23.125H13.125C12.7935 23.125 12.4755 22.9933 12.2411 22.7589C12.0067 22.5245 11.875 22.2065 11.875 21.875V20.625'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M14.375 17.5H20.625' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
      <line x1='17' y1='48' x2='17' y2='35' stroke='white' />
      <ellipse cx='17' cy='48.4688' rx='3.5' ry='3.50009' fill='#A6B2CB' />
    </svg>
  );
}
