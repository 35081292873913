import { getRenderingEngine } from '@cornerstonejs/core';
import ViewerMenu from 'components/ViewerMenu/ViewerMenu';
import LesionMenu from 'components/menuInputs/LesionMenu';
import Canvas from 'components/ui/canvas/Canvas';
import UISlider from 'components/ui/slider/Slider';
import { SliderValue, VolumeIds } from 'types';
import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import useView from 'hooks/useView';
import { RENDERING_ENGINE_ID, VIEW_STATE } from '../../constants';
import { StackIcon } from '../ui/icons';
import './Viewer.css';

const BottomMenu = ({ hasRendered, sliderValue }) => (
  <div className='bottom-menu'>
    <div className='bottom-nav font-weight-500'>
      <StackIcon />
      {hasRendered
        ? `${sliderValue.currentSlice + 1}/${sliderValue.maxSlice}`
        : ''}
    </div>
    <div className='bottom-nav'>Not for diagnostic use</div>
  </div>
);

export default function Viewer({
  initialVolumeIds,
  showLesionMenu,
}: {
  initialVolumeIds: VolumeIds;
  showLesionMenu: boolean;
}) {
  const [activeView] = useView();
  useEffect(() => {
    const handleResize = debounce(() => {
      if (activeView !== VIEW_STATE.TIMELINE) {
        const engine = getRenderingEngine(RENDERING_ENGINE_ID);
        if (engine) {
          engine.resize();
          engine.render();
        }
      }
    }, 20);

    if (activeView !== VIEW_STATE.TIMELINE) {
      window.addEventListener('resize', handleResize);
      // Force initial resize
      handleResize();
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [activeView]);
  const [sliderValue, setSliderValue] = useState<SliderValue>({
    currentSlice: 0,
    maxSlice: 0,
  });
  const hasRendered = sliderValue.maxSlice > 0;

  // Flexbox with 2 items, column, space between for top/bottom canvas separation
  return (
    <div className={`nifti-viewer ${activeView === VIEW_STATE.TIMELINE ? 'none' : ''}`}>
      <Canvas initialVolumeIds={initialVolumeIds} setSliderValue={setSliderValue} />
      <div className='menu-spacing'>
        <div className='top-menu-container'>
          <ViewerMenu />
        </div>
        <div className={`bottom-menu-container ${showLesionMenu ? '' : 'add-margin-bottom'}`}>
          <BottomMenu hasRendered={hasRendered} sliderValue={sliderValue} />
          <UISlider setSliderValue={setSliderValue} value={sliderValue} />
          {showLesionMenu && <LesionMenu />}
        </div>
      </div>
    </div>
  );
}
