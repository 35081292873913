import { eventTarget } from '@cornerstonejs/core';
import { Enums as NiftiEnums } from '@cornerstonejs/nifti-volume-loader';
import { useCallback, useEffect, useState } from 'react';

export default function useLoadingProgress() {
  const [isLoadingAnVolume, setIsLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);

  const handleVolumeProgress = useCallback((event: any) => {
    const isMainVolume = !event.detail.data.volumeId.includes('segmentation');
    if (!isMainVolume) return;
    setIsLoading(true);

    const { loaded, total } = event.detail.data;
    const progress = Math.round((loaded / total) * 100);
    setLoadingProgress(progress);
  }, []);

  const handleIsLoaded = useCallback((event: any) => {
    const isMainVolume = !event.detail.data.volumeId.includes('segmentation');
    if (!isMainVolume) return;
    setIsLoading(false);
    setLoadingProgress(0);
  }, []);
  useEffect(() => {
    eventTarget.addEventListener(NiftiEnums.Events.NIFTI_VOLUME_PROGRESS, handleVolumeProgress);
    eventTarget.addEventListener(NiftiEnums.Events.NIFTI_VOLUME_LOADED, handleIsLoaded);
    return () => {
      eventTarget.removeEventListener(
        NiftiEnums.Events.NIFTI_VOLUME_PROGRESS,
        handleVolumeProgress,
      );
      eventTarget.removeEventListener(NiftiEnums.Events.NIFTI_VOLUME_LOADED, handleIsLoaded);
    };
  }, []);

  return { isLoadingAnVolume, loadingProgress };
}
