import CtaTimelineIcon from 'components/ui/icons/CtaTimeline';
import NcctTimelineIcon from 'components/ui/icons/NcctTimeline';
import React from 'react';
import './ImageAnalysisCard.css';

import { ImageAnalysisEvent } from 'types';
import { formatIsoToHHMM } from 'utils/time';

interface ImageAnalysisCardProps {
  timeLineObject: ImageAnalysisEvent;
}

export default function ImageAnalysisCard({ timeLineObject }: ImageAnalysisCardProps) {
  const isSuspected = Object.values(timeLineObject).some(
    (value) => typeof value === 'string' && value.includes('Suspected'),
  );

  const formattedDate = formatIsoToHHMM(timeLineObject.event_datetime);
  const formattedReceptionTime = formatIsoToHHMM(timeLineObject.reception_time);

  const volumeKeys = Object.keys(timeLineObject).filter((key) => key.includes('volume'));

  const coloredResult = (
    <div className='headline-result'>
      <span>{'Processed - '}</span>
      <span className={isSuspected ? 'suspected-text' : 'blue-text'}>
        {timeLineObject.analysis_result}
      </span>
    </div>
  );
  const volumeText = volumeKeys.map((key, index) => (
    <span className='opacity-text' key={index}>
      {key.split('_').slice(1).join(' ')}: {timeLineObject[key]}
    </span>
  ));

  const icon = timeLineObject.image_type === 'CTA' ? <CtaTimelineIcon /> : <NcctTimelineIcon />;

  const headerRow = (
    <div className='timeline-card-row'>
      <div className='first-column'>
        <div className='timeline-icon'>{icon}</div>
      </div>
      <div className='second-column'>{timeLineObject.image_type}</div>
      <div className='third-column'>{formattedDate}</div>
    </div>
  );

  const mainContentRow = (
    <div className='timeline-card-row'>
      <div className='first-column'></div>
      <div className='second-column info-container'>
        {coloredResult}
        {volumeKeys.length > 0 && volumeText}
      </div>
      <div className='third-column opacity-text'>{formattedReceptionTime}</div>
    </div>
  );

  return (
    <div className='timeline-card-container'>
      {headerRow}
      {mainContentRow}
    </div>
  );
}
