import InfoModal from 'components/Modals/infomodal/Infomodal';
import { AxialOrientation,
  CoronalOrientation,
  ExpandIcon,
  InfoIcon,
  MinimizeIcon,
  ResetIcon,
  SagittalOrientation,
  WindowingIcon } from 'components/ui/icons';
import UIIconMenu from 'components/ui/menus/icon/IconMenu';
import UiModal from 'components/ui/modal/Modal';
import { useWindowing } from 'context/Windowing';
import useView from 'hooks/useView';
import React from 'react';
import { getViewport, resetFilters } from 'utils/utilities';
import { VIEW_STATE } from '../../constants';
import OrientationMenu from './OrientationMenu';
import WindowingMenu from './WindowingMenu';

type defaultMenuProps = {
  setTopBar: React.Dispatch<React.SetStateAction<React.ReactNode>>;
};

const DefaultCanvasMenu = ({ setTopBar }: defaultMenuProps) => {
  const [activeView, updateView] = useView();
  const isAdvancedMenuOpen = activeView === VIEW_STATE.ADVANCED;

  const { setWindowingValues } = useWindowing();
  const viewPort = getViewport();
  const orientation = viewPort?.defaultOptions?.orientation || 'axial';
  const iconMap = {
    axial: AxialOrientation,
    sagittal: SagittalOrientation,
    coronal: CoronalOrientation,
  };

  const buttons = [
    {
      icon: <UiModal icon={<InfoIcon />} content={<InfoModal />} />,
    },
    {
      icon: WindowingIcon,
      onClick: () => setTopBar(<WindowingMenu setTopBar={setTopBar} />),
    },
    {
      icon: iconMap[orientation],
      onClick: () => setTopBar(<OrientationMenu setTopBar={setTopBar} />),
    },
    {
      icon: ResetIcon,
      onClick: () => resetFilters(setWindowingValues),
    },
  ];

  return (
    <UIIconMenu
      buttons={buttons}
      rightIcon={
        !isAdvancedMenuOpen ? {
          icon: ExpandIcon,
          onClick: () => updateView(VIEW_STATE.ADVANCED),
        }
          : {
            icon: MinimizeIcon,
            onClick: () => updateView(VIEW_STATE.BASE),
          }
      }
    />
  );
};

export default DefaultCanvasMenu;
