import UIAccordion from 'components/ui/accordion/Accordion';
import { CakeIcon, DateIcon, GenderIcon, ProfileIcon } from 'components/ui/icons';
import UITable from 'components/ui/table/Table';
import React from 'react';
import { Patient } from 'types';
import { formatIsoToMonthDayHourMinute12h } from 'utils/time';

type tableData = {
  icon: React.ReactNode;
  label: string;
  value: string | number;
};

type PatientInfoProps = {
  patientData: Patient;
  studyDate: string;
};

const PatientInfo = ({ patientData, studyDate }: PatientInfoProps) => {
  const headerString = `${patientData.age}, ${patientData.gender}, ${formatIsoToMonthDayHourMinute12h(
    studyDate,
  )}`;
  const iconMap = {
    age: CakeIcon,
    gender: GenderIcon,
    date: DateIcon,
  };
  const formattedHeader = {
    value: headerString,
    expandedValue: 'Patient Information',
  };

  // Create a new object with the updated data instead of modifying patientData
  const updatedPatientData = {
    ...patientData,
    date: studyDate,
  };

  function capitalizeFirstLetter(inputValue: string | number): string {
    return inputValue.toString().charAt(0).toUpperCase() + inputValue.toString().slice(1);
  }

  const patientInfoData: tableData[] = Object.keys(updatedPatientData).map((key) => {
    const value = updatedPatientData[key as keyof typeof updatedPatientData];
    const icon = iconMap[key as keyof typeof iconMap];
    return {
      icon,
      label: capitalizeFirstLetter(key),
      value:
        key === 'date'
          ? formatIsoToMonthDayHourMinute12h(value as string)
          : capitalizeFirstLetter(value as string | number),
    };
  });

  return (
    <div>
      <UIAccordion
        icon={ProfileIcon}
        header={formattedHeader}
        detailedComponent={<UITable className='patient-info-table' data={patientInfoData} />}
      />
    </div>
  );
};

export default PatientInfo;
