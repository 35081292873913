import React from 'react';
import './Switch.css';

interface CustomSwitchProps {
  isChecked: boolean;
  onToggle: () => void;
}

const Switch: React.FC<CustomSwitchProps> = ({ isChecked, onToggle }) => (
  <label className='switch'>
    <input type='checkbox' checked={isChecked} onChange={onToggle} />
    <span className='toggle-slider' />
  </label>
);

export default Switch;
